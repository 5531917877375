export default{
    setSpinner(state, value){
        state.spinner = value;
    },
    setMenu(state, value){
        state.menu = value;
    },
    showModal(state) {
        state.toggle = true;
    },
    hideModal(state) {
        state.toggle = false;
    },

    setSales(state, payload) {
        //console.log("Prima:",JSON.parse(JSON.stringify(payload)));
        state.sales = payload;
        //console.log("Dopo:",JSON.parse(JSON.stringify(state.sales)));
    },
    setTotalSales(state, payload) {
        state.totalsales = payload;
    },
    setTotBikiniSold(state, payload){
        state.totBikiniSold = payload;
    },
    setTotBikiniStock(state, payload){
        state.totBikiniStock = payload;
    },
    setTotVoucher(state, payload){
        state.totVoucher = payload;
    },
    setIdSale(state, payload){
        state.idSale = payload;
    },
    setProducts(state, payload) {
        state.products = payload;
    },
    setProdsChange(state, payload){
        state.prodsChange = payload;
    },
    emptyReceipt(state) {
        state.prodsReceipt = [];
        state.total = 0;
        state.change = 0;
    },
    setExChange(state,payload){
        state.exchange = payload;//variabile cambio
    },
    setStatusRefund(state,payload){
        state.refund = payload;
    },
    setTotal(state, payload) {//totale scontrino
        state.total = payload;
    },
    setChange(state,payload){
        state.change = payload;//resto per contanti
    },
    setTransfers(state, payload) {
        state.transfers = payload;
    },
    setSearchString(state, payload){
        state.searchstring = payload;
    },
    setSearchSale(state, payload){
        state.searchsale = payload;
    },
    setDeviceConn(state, payload){
        state.deviceconn = payload;
    },
    setSellsAlert(state, payload){
        state.sellsAlert = payload;
    },
    setShowAlertStock(state, payload){
        console.log(state.showAlertStock);
        state.showAlertStock = payload;
    },
    setMessage(state, payload){
        state.message = payload;
    },
    setProductDetail(state, payload){
        state.productDetail = payload;
    },
    setShowModalDetails(state, payload){
        state.showModalDetails = payload;
    }
    /*setCategories(state, payload) {
       state.categories = payload;
   },
   setIdCategory(state, payload) {
       state.idCategory = payload;
   },*/
}