export default {
    getSpinner(state){
        return state.spinner;
    },
    getMenu(state){
        return state.menu;
    },
    //prodotti dello scontrino
    getProdsReceipt(state) {
        return state.prodsReceipt;
    },
    //totale scontrino
    getTotal(state) {
        return state.total;
    },
    //elenco prodotti
    getProducts: (state) => {
        return state.products;
    },
    getTransfers: (state) => {
        return state.transfers;
    },
    getSales: (state) => {
        return state.sales;
    },
    getIdSale: (state) => {
        return state.idSale;
    },
    getTotalSales: (state) => {
        return state.totalsales;
    },
    getTotBikiniSold: (state) => {
        return state.totBikiniSold;
    },
    getTotBikiniStock: (state) => {
        return state.totBikiniStock;
    },
    getTotVoucher: (state) => {
        return state.totVoucher;
    },
    getChange(state){
        return state.change;
    },
    getExChange(state){
        return state.exchange;
    },
    getProdsChange: (state) => {
        return state.prodsChange;
    },
    getSearchString(state){
        return state.searchstring;
    },
    getSearchSale(state){
        return state.searchsale;
    },
    getDeviceConn(state){
        return state.deviceconn;
    },
    getSellsAlert(state){
        return state.sellsAlert;
    },
    //dettaglio prodotto al click sul pacchetto
    getProductDetail(state){
        return state.productDetail;
    },
    //modale del dettaglio prdotto
    getShowModalDetails(state){
        return state.showModalDetails;
    }
    //elenco categorie
    /**getCategories: (state) => {
        return state.categories;
    },
     getIdCategory: (state) => {
        return state.idCategory;
    },*/
}